import React, { useMemo } from "react";
import useFetch from "../../utils/useFetch";
import { Chart } from "react-charts";

import ReactTooltip from "react-tooltip";
import TableWithSorting from "./TableWithSorting";

const TasksCountTable = ({agentIDs, startDate, endDate}) => {
  const data = useFetch(`/agent-performance/tasks-count-table-data?agent=${agentIDs}&start_date=${startDate}&end_date=${endDate}`);
  const headCells = useMemo(() => [
    {
      id: "username", numeric: false, label: "Agent Name"
    },
    {
      id: "to_consensus", numeric: true, label: "To Consensus"
    },
    {
      id: "directly_labelled", numeric: true, label: "Directly Labelled"
    },
    {
      id: "consensus_checking", numeric: true, label: "Consensus Checking"
    },
    {
      id: "segmentation", numeric: true, label: "Segmentation"
    },
    {
      id: "segmentation_checking", numeric: true, label: "Segmentation Checking"
    },
    {
      id: "total", numeric: true, label: "Total"
    },
  ], []);

  if (data.error)
    return <div>Failed to load: {data.error}</div>;
  if (!data.loaded)
    return <div>Loading...</div>;
  const rows = Object.keys(data.data).filter((k) => k !== "total").map((k) => data.data[k]);

  return <TableWithSorting headCells={headCells} rowData={rows} lastRow={data.data.total} />;
};

const _capitalize_snake_case = (s) => s.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());

const TasksCountBarChart = (props) => {
  const data = useFetch(
    `/agent-performance/tasks-count-chart-data?agent=${props.agentIDs}&start_date=${props.startDate}&end_date=${props.endDate}`,
    {
      extract: (data) => Object.keys(data).map((key) => ({
        label: _capitalize_snake_case(key),
        data: Object.keys(data[key]).map((k) => [Date.parse(k), data[key][k]])
      }))
    }
  );

  const series = useMemo(() => ({ type: "bar" }), []);

  const axes = useMemo(() => [
    {
      type: "time", position: "bottom", primary: true
    },
    {
      type: "linear", position: "left", stacked: true
    }
  ], []);

  if (data.error)
    return <p>Failed to load: {data.error}</p>;
  if (!data.loaded || !data.data.length)
    return <p>Loading...</p>;

  return <div
    style={{
      width: "100%", height: "22em", margin: "1em 0 4em", overflow: "hidden"
    }}>
    <h2 style={{textAlign: "center"}}>Tasks count evolution over time</h2>
    <div style={{height: "20em"}}>
      <Chart data={data.data} series={series} axes={axes} tooltip padding="5" />
    </div>
  </div>;
};

const TasksCountTooltip = () => <ReactTooltip id="tasksCountTableTip" event="click" globalEventOff="click" place="right" type="info" offset={{"top": -100}} arrowColor="transparent">
  <div className="headline">Definitions</div>
  <div className="subheadline">To Consensus</div>
  <div className="text">Number of labelling tasks going into consensus.</div>
  <div className="subheadline">Directly Labelled</div>
  <div className="text">Number of labelling tasks labeled directly by KITRONs.</div>
  <div className="subheadline">Consensus Checking</div>
  <div className="text">Number of trashevents checked by the agent because they had a conflict after going into consensus.</div>
  <div className="subheadline">Segmentation</div>
  <div className="text">Number of segmented trashevents.</div>
  <div className="subheadline">Segmentation Checking</div>
  <div className="text">Number of segmentation tasks checked.</div>
  <div className="subheadline">Total</div>
  <div className="text">Sum of labelling, segmentation, and checking tasks.</div>
</ReactTooltip>;

export {
  TasksCountTable, TasksCountBarChart, TasksCountTooltip
};
