import React from "react";
import ImageContainer from "../Labelling/ImageContainer";
import LabelsContainer from "../Labelling/LabelsContainer";
import type { Label, LabelSelection } from "../../containers/LabelSelector";
import Modal from "react-modal";
import {HotKeys} from "react-hotkeys";


const LabellingDataValidity: React.FC<{
    title: string,
    taskData: {
        trashevent_id: number,
        weight: number,
        device_id: number,
        labels: LabelSelection[],
        errors: number[],
    },
    imageURL: string,
    prevImageURL: string,
    requestClose: () => void,
    saveImageChanges: (ls: LabelSelection[], err: number[]) => void,
    cancelChanges?: (ls: LabelSelection[], err: number[]) => void,
    labelData: Label[]
}> = ({
  title,
  taskData,
  requestClose,
  imageURL,
  prevImageURL,
  saveImageChanges,
  cancelChanges,
  labelData
}) => {
  const handlers = {"abortChanges": (e) => { e.preventDefault(); requestClose(); }};

  const filterLabels = (labels: LabelSelection[]) => labels.filter((label) => labelData.find((ld) => ld.id === label.id) !== undefined);

  return (
    <div className="labelEdit">
      <Modal
        isOpen={true}
        onRequestClose={requestClose}
        contentLabel="LabellingModal"
        style={{
          content: {
            width: "165vh",
            height: "92vh",
            left: "50%",
            top: "53%",
            transform: "translate(-50%, -50%)",
          }
        }}
        appElement={document.getElementById("root")}
      >
        <HotKeys keyMap={{"abortChanges": "alt+e" }} handlers={handlers}>
          <div className="animated fadeIn">
            {title && <h1 style={{marginTop: "-15px"}}>{title}</h1>}
            <div
              style={{
                marginBottom: "5px", fontWeight: "bold", fontSize: "1rem", maxWidth: "64%"
              }}>
              <span style={{marginRight: "18%"}}>{"Device ID: " + (taskData.device_id)}</span>
              <span style={{marginRight: "18%"}}>{"Added Weight: " + (taskData.weight) + "g"}</span>
              <span>{"Event ID: " + (taskData.trashevent_id)}</span>
            </div>
            <ImageContainer
              prevImage={prevImageURL}
              currImage={imageURL}
            />
            <LabelsContainer
              topDistance={68}
              nextImageFunction={saveImageChanges}
              addAndNextFunction={cancelChanges}
              nextButtonString="Apply"
              addAndNextButtonString="Cancel"
              currentLabel={filterLabels(taskData.labels)}
              currentError={taskData.errors}
              currentImageData={taskData}
              labels={labelData}
            />
          </div>
        </HotKeys>
      </Modal>
    </div>
  );
};

export default LabellingDataValidity;
